import React from "react";

import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";

import { CookieLaw, CookieProvider, GlobalStyle } from "../components";

import { schmiedekultursolingen } from "../theme";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]', { offset: () => 100 });
}

export const DefaultLayout = (props) => {
  return (
    <ThemeProvider theme={schmiedekultursolingen}>
      <CookieProvider>
        <GlobalStyle />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Schmiede Kulturverein Solingen</title>
          <meta name="description" content="Bla" />
        </Helmet>
        <CookieLaw />
        {props.children}
      </CookieProvider>
    </ThemeProvider>
  );
};
