import React from 'react'
import { Link } from 'gatsby'
import Image from 'gatsby-image'

import { NavigationLogoBox } from './styles'

interface LogoProps {
  logo: any
  title: string
}

export const NavigationLogo = ({ logo, title }: LogoProps) => (
  <NavigationLogoBox>
    <Link to="/">
      {logo && (
        <Image
          fluid={logo.childImageSharp.fluid}
          style={{
            backgroundSize: 'contain',
          }}
        />
      )}
    </Link>
  </NavigationLogoBox>
)
