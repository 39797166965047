import styled from 'styled-components'

import Img from 'gatsby-image'

export const BlogItemImage = styled(Img)`
  width: 100%;
  height: auto;
  @media (min-width: 768px) {
    min-width: 175px;
    min-height: 175px;
    width: 175px;
    height: 175px;
  }
`
export const BlogBox = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 768px) {
      flex-wrap: nowrap;
  }
`

export const BlogItemBox = styled.div`
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.primary};
  width: 100%;
`

export const BlogItemText = styled.div`
  padding: 0 1rem 1rem;
  @media (min-width: 768px) {
    padding: 0 1rem;
  }
`

export const BlogItemTitle = styled.h2``

export const BlogItemDescription = styled.div``
