import styled from 'styled-components'

export const NavigationBox = styled.div`
  background-color: black;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 0.3s;
  box-shadow: 0 0.6px 0.6px rgba(0, 0, 0, 0.062), 0 1.5px 1.5px rgba(0, 0, 0, 0.089), 0 2.9px 2.9px rgba(0, 0, 0, 0.11),
    0 5.1px 5.1px rgba(0, 0, 0, 0.131), 0 9.6px 9.6px rgba(0, 0, 0, 0.158), 0 23px 23px rgba(0, 0, 0, 0.22);
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  min-height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 5vw;
`
