import styled from 'styled-components'

export const NavigationLogoBox = styled.div`
  transition: all 0.3s;
  width: 100px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  @media (min-width: 768px){
    position: static;
    margin-left: 0;
    margin-right: 0;
  }
`
