import styled from 'styled-components'

interface NavigationMenuProps {
  readonly isNavigationOpen: boolean
}

export const NavigationMenu = styled.nav<NavigationMenuProps>`
  display: flex;
  position: absolute;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  left: 0;
  top: 100%;
  width: 300px;
  background-color: black;
  z-index: 998;
  transform: ${(props) => !props.isNavigationOpen ? 'translateX(-300px)' : 'translateX(0)'};
`
