import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

export const GlobalStyle = createGlobalStyle`
    ${normalize}

    * {
        box-sizing: border-box;
    }

    body {
        background: black;
        color: white;
        font-family: Libre Franklin, Helvetica Neue, helvetica, arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .grecaptcha-badge {
        display: none !important;
    }

    a {
        color: white;
    }
`
