import React from 'react'
import ImageGallery from 'react-image-gallery'
import './image-gallery.css'

import { GalleryWrapperBox } from './styles'

export const GalleryWrapper = ({images}) => {
  const compareObjects = (a, b) => {
    const aName = a.name.toUpperCase()
    const bName = b.name.toUpperCase()

    let comparison = 0
    if (aName > bName) {
      comparison = 1
    } else if (aName < bName) {
      comparison = -1
    }
    return comparison
  }

  const mapImages = (oldImages) => {
    const imageArray = []
    oldImages.map((image: any) => {
      let object = {
        name: image.directus_files_id.title,
        original: image.directus_files_id.imageUrl.childImageSharp.fluid.src,
        thumbnail: image.directus_files_id.imageUrl.childImageSharp.fluid.src,
      }
      imageArray.push(object)
    })
    return imageArray.sort(compareObjects)
  }

  return (
    <GalleryWrapperBox>
      <ImageGallery
        additionalClass="gallery"
        showFullscreenButton={false}
        lazyload={true}
        showPlayButton={false}
        items={mapImages(images)}
      />
    </GalleryWrapperBox>
  )
}
