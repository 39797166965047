import React from 'react'

import { IFrameBox, IFrameWrapper } from './styles'

interface Props {
  readonly ytid: string
  readonly aspectRaito?: {
    width: number
    height: number
  }
}

const defaultProps = {
  aspectRaito: {
    width: 16,
    height: 9,
  },
}

const YouTubeFrame = ({ ytid, aspectRaito }: Props) => {
  const ytlink = "https://www.youtube.com/embed/" + ytid
  return (
    <IFrameBox aspectRaito={aspectRaito}>
      <IFrameWrapper src={ytlink} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
    </IFrameBox>
  )
}

YouTubeFrame.defaultProps = defaultProps

export { YouTubeFrame }
 