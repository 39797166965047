import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie'
import { Link } from 'gatsby'

import { Button } from '../Button'
import { CookieLawBox, CookieLawButtonBox, CookieText } from './CookieLawStyles'
import { useCookieDispatch } from './CookieContext'

export const CookieLaw = () => {
  const [visible, setVisible] = useState(false)
  const dispatch = useCookieDispatch()

  useEffect(() => {
    const cookie = new Cookies()
    if (cookie.get('cookieLaw')) {
      console.log('test')
      dispatch({ type: 'enableAll' })
    }
    setVisible(cookie.get('cookieLaw') === undefined)
  }, [])

  const acceptCookieLaw = () => {
    const cookie = new Cookies()
    setVisible(false)
    cookie.set('cookieLaw', 'true')
    dispatch({ type: 'enableAll' })
  }

  return (
    <CookieLawBox visible={visible}>
      <CookieText>
        Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren
        Rechten als Benutzer finden Sie in unserer{' '}
        <Link to="/datenschutz#cookies">Datenschutzerklärung</Link>. Klicken Sie
        auf „Akzeptieren“, um Cookies zu akzeptieren und unsere Website besuchen
        zu können.
      </CookieText>
      <CookieLawButtonBox>
        <Button color="red" onClick={() => setVisible(false)}>
          Ablehnen
        </Button>
        <Button color="green" onClick={() => acceptCookieLaw()}>
          Akzeptieren
        </Button>
      </CookieLawButtonBox>
    </CookieLawBox>
  )
}
