import { DefaultTheme } from 'styled-components'

export const schmiedekultursolingen: DefaultTheme = {
  name: 'schmiedekultursolingen',
  defaultFontFamily: '"Libre Franklin", "Helvetica Neue", helvetica, arial, sans-serif',
  colors: {
    primary: 'rgb(145, 62, 32)',
    secondary: '#70AD58',
    background: 'black',
    text: 'white',
  },
  link: {
    color: 'white',
  },
  divider: {
    margin: '2.5rem 0',
  },
  container: {
    backgroundColor: 'black',
    mobilePadding: '5rem 1rem',
    padding: '6rem 1rem 0rem',
  },
  cookielaw: {
    backgroundColor: 'rgb(145, 62, 32)',
  },
  navigation: {
    background: 'black',
    scrollBackground: 'black',
    title: {
      color: 'rgb(145, 62, 32)',
    },
    hamburger: {
      width: 35,
      color: 'rgb(145, 62, 32)',
      borderRoundings: true,
      barWidth: 20,
    },
    link: {
      color: 'rgb(145, 62, 32)',
      size: '1.5rem',
      custom: `
        font-weight: 600;
      `,
    },
    linkPadding: '10px 0px',
    logo: {
      mobileMiddleAbsolut: true,
      width: {
        base: '100px',
        extraSmall: '100px',
        small: '100px',
        medium: '100px',
        large: '100px',
        extraLarge: '100px',
      },
      shrinkWidth: {
        base: '100px',
        extraSmall: '100px',
        small: '100px',
        medium: '100px',
        large: '100px',
        extraLarge: '100px',
      },
    },
    titleMobileVisible: false,
    menuWidth: '300px',
    menuPosition: 'left',
    logoPosition: 'right',
    titlePosition: 'middle',
  },
}