import styled, { keyframes } from 'styled-components'

interface CookieLawBoxProps {
  visible: boolean
}

const boxAnimation = () => keyframes`
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0%;
  }
`

export const CookieLawBox = styled.div<CookieLawBoxProps>`
  bottom: 0;
  position: fixed;
  background-color: black;
  border-top: 1px solid rgb(145, 62, 32);
  /* background-color: rgb(145, 62, 32); */
  min-height: 75px;
  z-index: 99;
  width: 100%;
  padding: 0.5rem 0rem 1.5rem;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  box-shadow: 0 0.5px 2.2px rgba(0, 0, 0, 0.037),
    0 1.3px 5.3px rgba(0, 0, 0, 0.053), 0 2.4px 10px rgba(0, 0, 0, 0.065),
    0 4.2px 17.9px rgba(0, 0, 0, 0.077), 0 7.9px 33.4px rgba(0, 0, 0, 0.093),
    0 19px 80px rgba(0, 0, 0, 0.13);
  @media (min-width: 768px) {
    padding: 0.5rem 0rem;
    flex-direction: row;
  }
  animation: ${boxAnimation} 0.5s ease-in-out;
`

export const CookieText = styled.div`
  padding: 1.5rem;
  @media (min-width: 768px) {
    width: 80%;
  }
`
export const CookieLawButtonBox = styled.div`
  display: flex;
  > * {
    &:first-child {
      margin-right: 1rem;
    }
  }
`
