import React, { useState } from 'react'
import { NavigationMenu, NavigationBox, NavigationOffscreen, NavigationLink, NavigationTitle } from './styles'

import { HamburgerIcon } from './HamburgerIcon'
import { NavigationLogo } from './NavigationLogo'

interface NavigationProps {
  readonly logo: any
  readonly title: string
  readonly menu: Array<{ title: string; path: string }>
}

export const Navigation = ({ logo, title, menu }: NavigationProps) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false)

  return (
    <NavigationBox>
      <NavigationMenu isNavigationOpen={isNavigationOpen}>
        {menu.map((linkobj, index) => {
          return (
            <NavigationLink
              key={String(index)}
              onClick={() => setIsNavigationOpen(!isNavigationOpen)}
              to={linkobj.path}
            >
              {linkobj.title}
            </NavigationLink>
          )
        })}
      </NavigationMenu>
      <HamburgerIcon onClickHandler={() => setIsNavigationOpen(!isNavigationOpen)} isNavigationOpen={isNavigationOpen} />
      <NavigationTitle to="/">{title}</NavigationTitle>
      <NavigationLogo title={title} logo={logo} />
      <NavigationOffscreen onClick={() => setIsNavigationOpen(!isNavigationOpen)} isNavigationOpen={isNavigationOpen} />
    </NavigationBox>
  )
}
