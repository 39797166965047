import React from 'react'
import { Link } from 'gatsby'

import {
  BlogBox,
  BlogItemBox,
  BlogItemImage,
  BlogItemTitle,
  BlogItemDescription,
} from './styles'

interface Props {
  readonly projects: Array<{
    title: string
    order: string
    slug: string
    previewtext: string
    wireframe: any
  }>
}

const sortProject = (projectA, projectB) =>
  parseInt(projectA.order) > parseInt(projectB.order) ? -1 : 1

export const ProjectList = ({ projects }: Props) => (
  <div>
    {projects.sort(sortProject).map((item, index) => {
      return (
        <BlogItem
          key={index}
          image={item.wireframe}
          title={item.title}
          slug={item.slug}
          description={item.previewtext}
        />
      )
    })}
  </div>
)

interface ItemProps {
  title: string
  slug: string
  image: any
  description: string
}

const BlogItem = ({ title, slug, image, description }: ItemProps) => (
  <Link style={{ textDecoration: 'none', margin: '1rem' }} to={`/${slug}`}>
    <BlogBox>
      <BlogItemImage fluid={image.imageUrl.childImageSharp.fluid} />
      <BlogItemBox>
        <BlogItemTitle>{title}</BlogItemTitle>
        <BlogItemDescription>
          {description} <Link to={`/${slug}`}>Mehr ...</Link>
        </BlogItemDescription>
      </BlogItemBox>
    </BlogBox>
  </Link>
)
