import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export const NavigationTitle =  styled((props) => <Link {...props} />)`
  font-size: 2.5rem;
  letter-spacing: 2px;
  font-weight: bold;
  display: none;
  color: rgb(145, 62, 32);
  text-decoration: none;
  @media (min-width: 768px){
    display: block;
  }
`