import React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'

// eslint-disable-next-line react/jsx-props-no-spreading
export const NavigationLink = styled((props) => <Link {...props} />)`
  transition: all 0.3s ease 0s;
  text-decoration: none;
  font-size: 1.5rem;
  color: rgb(145, 62, 32);
  font-weight: 600;
  padding: 10px 0px;
  &:hover {
    text-decoration: underline;
  }
`
