import React, { createContext, useReducer, useContext } from 'react'
import { CookieDispatch, CookieState, CookieAction } from './CookieContextTypes'

const CookieDispatchContext = createContext({} as CookieDispatch)
const CookieStateContext = createContext({} as CookieState)

const contextReducer = (state: CookieState, action: CookieAction) => {
  switch (action.type) {
    case 'enableMap': {
      return {
        ...state,
        isMapsEnabled: true,
      }
    }
    case 'enableCaptcha': {
      return {
        ...state,
        isCaptchaEnabled: true,
      }
    }
    case 'disableCaptcha': {
      return {
        ...state,
        isCaptchaEnabled: false,
      }
    }
    case 'enableAll': {
      return {
        ...state,
        isMapsEnabled: true,
        isCaptchaEnabled: true,
      }
    }
    default: {
      throw new Error(`Unhandled action type`)
    }
  }
}

type CookieProviderProps = {
  children: React.ReactNode
}

export const CookieProvider = ({ children }: CookieProviderProps) => {
  const [state, dispatch] = useReducer(contextReducer, {
    isMapsEnabled: false,
    isCaptchaEnabled: false,
  })
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  return (
    <CookieDispatchContext.Provider value={dispatch}>
      <CookieStateContext.Provider value={state}>{children}</CookieStateContext.Provider>
    </CookieDispatchContext.Provider>
  )
}

export const useCookieState = () => {
  const context = useContext(CookieStateContext)
  if (context === undefined) {
    throw new Error('useCookie must be used within a CountProvider')
  }
  return context
}

export const useCookieDispatch = () => {
  const context = useContext(CookieDispatchContext)
  if (context === undefined) {
    throw new Error('useCookie must be used within a CountProvider')
  }
  return context
}
