import React from 'react'

import { Button } from '../Button'
import { useCookieState, useCookieDispatch } from '../CookieLaw'

import {
  IFrameBox,
  IFrameWrapper,
  IFrameEmtpyWrapper,
} from './MapsIFrameStyles'

interface IFrameProps {
  readonly link: string
  readonly title: string
  readonly aspectRaito?: {
    width: number
    height: number
  }
}

const defaultProps = {
  aspectRaito: {
    width: 16,
    height: 9,
  },
}

export const MapsIFrame = ({ link, title, aspectRaito }: IFrameProps) => {
  const state = useCookieState()
  const dispatch = useCookieDispatch()

  return state.isMapsEnabled ? (
    <IFrameBox aspectRaito={aspectRaito}>
      <IFrameWrapper src={link} title={title} />
    </IFrameBox>
  ) : (
    <IFrameBox aspectRaito={aspectRaito}>
      <IFrameEmtpyWrapper>
        <p>
          Um Google Maps zu verwenden, müssen Sie unsere Datenschutzerklärung
          akzeptieren.
        </p>
        <Button color="green" onClick={() => dispatch({ type: 'enableMap' })}>
          Akzeptieren
        </Button>
      </IFrameEmtpyWrapper>
    </IFrameBox>
  )
}

MapsIFrame.defaultProps = defaultProps
