import styled, { css } from 'styled-components'

interface HamburgerProps {
  readonly isNavigationOpen: boolean
}

export const HamburgerBox = styled.div<HamburgerProps>`
  cursor: pointer;
  position: relative;
  transition: transform 0.3s;
  margin-right: 5vw;
  width: 35px;
  height: 35px;
`

interface HamburgerBarProps {
  readonly isNavigationOpen: boolean
  readonly number: 1 | 2 | 3
}

export const HamburgerBar = styled.div<HamburgerBarProps>`
  height: 20%;
  transition: opacity 0.35s, transform 0.4s;
  background-color: rgb(145, 62, 32);
  border-radius: 4px;

  // eslint-disable-next-line consistent-return
  ${({ number, isNavigationOpen }) => {
      switch (number) {
        default:
        case 1:
          return css`
            margin-bottom: 20% ;
            transform: ${isNavigationOpen && 'rotate(-45deg) translate(-9.66px, 9.66px)'} ;
          `
        case 2:
          return css`
            opacity: ${isNavigationOpen && '0'};
          `
        case 3:
          return css`
            margin-top: 20%;
            transform: ${isNavigationOpen && 'rotate(45deg) translate(-9.66px, -9.66px)'} ;
          `
      }
  }}
`
