import styled, {css} from 'styled-components'

interface CarouselItemBoxProps{
    disabled: boolean
}

interface CarouselDotProps{
    active: boolean
}


//TODO: Refactor CarouselHeight
export const CarouselBox = styled.div`
    position: relative;
    background: #0000AA;
    width: 100%;
    height: 100vh;
`


export const CarouselItemBox = styled.div<CarouselItemBoxProps>`
    position: absolute;
    background: black;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out 0.5s;
    ${props => props.disabled && css`
        opacity: 0;
    `};
`


export const CarouselTextBox = styled.div`
    position: absolute;
    bottom:5%;
    right: 5%;
    background-color: rgba(0,0,0,0.8);
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
    border-radius: 5px;
    padding: 0 1rem 1rem 1rem;
    max-width: 300px;
    z-index: 2;
    a{
        color: ${(props) => props.theme.colors.primary} ;
    }
`

export const CarouselImageBox = styled.div`
    & .carouselImage{
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: 1;
    }
`

export const CarouselDotsStyle = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    margin: 10px auto;
    padding: 0 5vw;
    z-index: 2;
`

export const CarouselDotStyle = styled.div<CarouselDotProps>`
    width:1rem;
    height:1rem;
    margin: 0 10px;
    border-radius: 50%;
    background-color: white;
    transition: background-color 0.5s ease-in-out 0.5s;
    ${props => props.active && css`
        background-color: gray;
    `};
`