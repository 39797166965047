import React from 'react'

import { Navigation as DefaultNavigation } from '../components'

const menu = [
  { title: 'Home', path: '/' },
  { title: 'Projekte', path: '/projekte' },
  { title: 'Anfahrt', path: '/anfahrt' },
  { title: 'Über uns', path: '/ueber-uns' },
  { title: 'Impressum', path: '/impressum' },
  { title: 'Datenschutz', path: '/datenschutz' },
]

export const Navigation = ({ logo }) => (
  <DefaultNavigation
    menu={menu}
    title={'Schmiedekultur-Verein'}
    logo={logo}
  />
)

