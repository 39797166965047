import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import {
  CarouselBox,
  CarouselImageBox,
  CarouselItemBox,
  CarouselTextBox,
  CarouselDotsStyle,
  CarouselDotStyle,
} from './styles'

interface Props {
  carouselData: Array<{
    title: string
    slug: string
    previewtext: string
    wireframe: any
  }>
}

export const Carousel = ({ carouselData }: Props) => {
  const [activeSlide, setActiveSlide] = useState(0)

  const sliderChange = (slidenumber: number) => {
    console.log(slidenumber)
  }

  useEffect(() => {
    const interval = setInterval(
      () =>
        setActiveSlide((activeSlide) =>
          activeSlide + 1 === carouselData.length
            ? 0
            : activeSlide + 1
        ),
      6000
    )
    return () => clearInterval(interval)
  }, [])

  return (
    <CarouselBox>
      {carouselData.map(({title, slug, previewtext, wireframe}, index) => {
        return (
          <CarouselItem isEnabled={index === activeSlide} key={index}>
            <CarouselImageBox>
              <Img
                className={'carouselImage'}
                fluid={wireframe.imageUrl.childImageSharp.fluid}
              />
            </CarouselImageBox>
            <CarouselText
              text={previewtext}
              link={slug}
              linkname={title}
            />
          </CarouselItem>
        )
      })}
      <CarouselDots
        slides={carouselData}
        activeSlide={activeSlide}
        setActiveSlideHandler={setActiveSlide}
      />
    </CarouselBox>
  )
}

interface ItemProps {
  isEnabled: boolean
  children: any
}

const CarouselItem = ({ isEnabled, children }: ItemProps) => (
  <CarouselItemBox disabled={!isEnabled}>{children}</CarouselItemBox>
)

interface TextProps {
  text: string
  link: string
  linkname: string
}
const CarouselText = ({ text, link, linkname }: TextProps) => (
  <CarouselTextBox>
    <p>{text}</p>
    <Link to={link}>Link: {linkname}</Link>
  </CarouselTextBox>
)

interface DotsProps {
  slides: any
  activeSlide: number
  setActiveSlideHandler: (activeSlide: number) => void
}

const CarouselDots = ({
  slides,
  activeSlide,
  setActiveSlideHandler,
}: DotsProps) => (
  <CarouselDotsStyle>
    {slides.map((_, index) => {
      return (
        <CarouselDotStyle
          key={index}
          onClick={() => setActiveSlideHandler(index)}
          active={activeSlide === index ? true : false}
        />
      )
    })}
  </CarouselDotsStyle>
)
