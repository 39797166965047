import styled, { css } from 'styled-components'

interface FlexBoxProps {
  wrapSm?: boolean
  wrapMd?: boolean
  wrapLg?: boolean
  wrapXl?: boolean
  center?: boolean
}

export const FlexBox = styled.div<FlexBoxProps>`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `}
  @media (min-width: 567px) {
    flex-wrap: ${(props) => (props.wrapSm === false ? 'nowrap' : '')};
  }
  @media (min-width: 768px) {
    flex-wrap: ${(props) => (props.wrapMd === false ? 'nowrap' : '')};
  }
  @media (min-width: 992px) {
    flex-wrap: ${(props) => (props.wrapLg === false ? 'nowrap' : '')};
  }
  @media (min-width: 1200px) {
    flex-wrap: ${(props) => (props.wrapXl === false ? 'nowrap' : '')};
  }
`

interface FlexChildProps {
  shrink?: boolean
  grow?: boolean
  col?: number
  colSm?: number
  colMd?: number
  colLg?: number
  colXl?: number
}

export const FlexChild = styled.div<FlexChildProps>`
  flex-shrink: ${(props) => (props.shrink ? '0' : '1')};
  flex-grow: ${(props) => (props.grow ? '1' : '0')};
  flex-basis: ${(props) => (props.col ? `calc(100% / 12 * ${props.col});` : '')};
  margin: 0.5rem 0;
  @media (min-width: 567px) {
    flex-basis: ${(props) => (props.colSm ? `calc(100% / 12 * ${props.colSm});` : '')};
  }
  @media (min-width: 768px) {
    flex-basis: ${(props) => (props.colMd ? `calc(100% / 12 * ${props.colMd});` : '')};
    margin: 0;
  }
  @media (min-width: 992px) {
    flex-basis: ${(props) => (props.colLg ? `calc(100% / 12 * ${props.colLg});` : '')};
  }
  @media (min-width: 1200px) {
    flex-basis: ${(props) => (props.colXl ? `calc(100% / 12 * ${props.colXl});` : '')};
  }
`
