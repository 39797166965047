import styled from 'styled-components'

interface NavigationOffscreenProps {
  readonly isNavigationOpen: boolean
}

export const NavigationOffscreen = styled.div<NavigationOffscreenProps>`
  position: absolute;
  top: 100%;
  width: 100vw;
  height: 100vh;
  z-index: 997;
  background: rgba(0, 0, 0, 0.3);
  display: ${(props) => !props.isNavigationOpen && 'none'};
  @media (min-width: 768px) {
    display: none;
  }
`
